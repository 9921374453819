import { formIsValid } from 'utils/validation';
import { validateText, validateZipCode } from 'utils/validation/fields';
import { reduce } from 'lodash';
import {
  FORM_ACTION_TYPES,
  FORM_REQUIRED_FIELDS,
  formInitialState,
  formNames,
  retailerTypeOptions,
  ageRestriction,
  SUBCATEGORIES,
} from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action) => {
  switch (action.name) {
    case formNames.zipCode:
      return validateZipCode({
        value: action.payload.trim(),
        name: action.name,
        required: true,
        max: 10,
        min: 5,
      });
    case formNames.city:
      return validateText({
        value: action.payload.value?.trim(),
        name: action.name,
        required: true,
        max: 50,
      });
    default:
      return validateText({
        value: action.payload.trim(),
        name: action.name,
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 50,
      });
  }
};

const checkFormIsValid = (errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const validateSubcategories = (updatedState, categoryArray, category, action, formState) => {
  const validationFields = reduce(
    updatedState.attributes,
    (acc, value, key) => {
      return categoryArray.includes(key) ? [...acc, value] : acc;
    },
    [],
  );

  if (validationFields.some((el) => el === true)) {
    return {
      ...formState.data,
      attributes: {
        ...formState.data.attributes,
        [action.name]: action.payload,
        [category]: true,
      },
    };
  }
  if (validationFields.every((el) => el === false)) {
    return {
      ...formState.data,
      attributes: {
        ...formState.data.attributes,
        [action.name]: action.payload,
        [category]: false,
      },
    };
  }

  if (action.initAttributes && validationFields.every((el) => el === null || el === '')) {
    return {
      ...formState.data,
      attributes: {
        ...formState.data.attributes,
        [action.name]: action.payload,
        [category]: action.initAttributes[category],
      },
    };
  }

  return {
    ...formState.data,
    attributes: {
      ...formState.data.attributes,
      [action.name]: action.payload,
      [category]: null,
    },
  };
};

const getNewData = (formState, action) => {
  const isSubcategory = SUBCATEGORIES.find(({ name }) => name === action.name);
  if (isSubcategory) {
    const { category } = isSubcategory;
    const categoryArray = SUBCATEGORIES.filter((obj) => category === obj.category).map(({ name }) => name);

    const updatedState = {
      ...formState.data,
      attributes: {
        ...formState.data.attributes,
        [action.name]: action.payload,
      },
    };
    return validateSubcategories(updatedState, categoryArray, category, action, formState);
  }

  if (action?.name === formNames.residential && action?.payload) {
    return {
      ...formState.data,
      attributes: {
        ...formState.data.attributes,
        [action.name]: action.payload,
        [formNames.inBusiness]: false,
        [formNames.sellTobacco]: false,
        [formNames.sellCigarettes]: false,
        [formNames.sellCigars]: false,
        [formNames.sellEcigarettes]: false,
        [formNames.sellSmokeless]: false,
      },
    };
  }
  return {
    ...formState.data,
    attributes: {
      ...formState.data.attributes,
      [action.name]: action.payload,
    },
  };
};

const validateFormData = (data) =>
  FORM_REQUIRED_FIELDS.reduce((acc, name) => {
    acc[name] = validateData({
      payload: data.attributes[name],
      name,
    });
    return acc;
  }, {});

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = getNewData(formState, action);

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const { data } = formState;

      const newErrors = validateFormData(data);

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newErrors),
      };
    }

    case FORM_ACTION_TYPES.RESET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: false,
      };
    }

    case FORM_ACTION_TYPES.SET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload.attributes,
        },
        relationships: {
          ...action.payload.relationships,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: true,
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const setFormForEdit = (data) => {
  return Object.values(formNames).reduce((acc, item) => {
    switch (item) {
      case formNames.ageRestriction: {
        acc[item] = {
          label: ageRestriction[data[item]] || null,
          value: data[item] || 'Select an option',
        };
        return acc;
      }

      case formNames.city: {
        acc[item] = {
          value: data[item] || null,
          label: data[item] || 'Select an option',
        };
        return acc;
      }

      case formNames.type: {
        const checkedOption = retailerTypeOptions.find((typeItem) => typeItem.id === data[formNames.type]);
        acc[item] = checkedOption || {
          value: data[item] || null,
          label: data[item] || 'Select an option',
        };
        return acc;
      }

      case formNames.chain:
      case formNames.sellTobacco:
      case formNames.sellAlcohol:
      case formNames.sellCigarettes:
      case formNames.sellCigars:
      case formNames.sellEcigarettes:
      case formNames.sellSmokeless:
      case formNames.hasPharmacy:
      case formNames.hasAVC:
      case formNames.acceptsWIC:
      case formNames.acceptsSNAP:
      case formNames.inBusiness:
      case formNames.internetSales:
      case formNames.residential: {
        acc[item] = data[item];
        return acc;
      }
      case formNames.closeDate: {
        acc[item] = {
          value: data[item] || '',
          selected: data[item] || new Date(),
        };
        return acc;
      }
      default: {
        acc[item] = data[item] || '';
        return acc;
      }
    }
  }, {});
};

export const getRadioValue = (val) => {
  if (val === false) return false;
  if (val) return true;
  return null;
};
